import React, { useEffect, useRef, useState } from "react";
import "../styling/App.css"

const TypingEffect = ({ fixedText, dynamicTexts, period = 1000 }) => {
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [delta, setDelta] = useState(150);
  const isMounted = useRef(true);

  useEffect(() => {
    const handleTick = () => {
      const i = loopNum % dynamicTexts.length;
      const fullText = dynamicTexts[i];

      if (isDeleting) {
        setText(fullText.substring(0, text.length - 1));
        setDelta(75); 
      } else {
        setText(fullText.substring(0, text.length + 1));
      }

      if (!isDeleting && text === fullText) {
        setIsDeleting(true);
        setDelta(period);
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setDelta(150); // Speed between each text change
      }
    };

    if (isMounted.current) {
      const ticker = setTimeout(handleTick, delta);
      return () => clearTimeout(ticker);
    }

    return () => (isMounted.current = false);
  }, [text, isDeleting, delta, loopNum, dynamicTexts, period]);

  return (
    <div className="typewrite">
      <span className="wrap" dangerouslySetInnerHTML={{ __html: `${fixedText}${text}` }} />


    </div>
  );
};

export default TypingEffect;