import React from "react";
import "../styling/App.css"

import Projects from "./myProject";
import 'bootstrap-icons/font/bootstrap-icons.css';
import TypingEffect from "./typingEffect";

const mainPage = () => {
  return (
    <>
      <div className="profile-and-typewriter-container">
        <div className="containerpic">
          <img className="profilpic" src="llama.png" alt="NatTheCoder" />
         
        </div>
        <div className="typeWriter">
          <div className="name-container">
            <TypingEffect
              className="typewrite"
              fixedText="Hello, I am "
              dynamicTexts={[
                "Natalie",
                "a Software Developer",
                "a book worm",
                "a yogi",
             
              ]}
              period={1000}
            />
             <div className="icon-container">
        

             <a href="https://www.linkedin.com/in/natalie-vittini-a60604242/" className="bi bi-linkedin"></a>
            <a href="https://github.com/VittiniN" className="bi bi-github"></a> 
          </div>
          </div>
        </div>
      </div>
      <section>
        <div className="section2"></div>
      </section>
      <section className="section5">
        <Projects />
      </section>
    
      <div className="section4">
        <footer>
          <p className="footerMssg">© Natalie Vittini 2022</p>
        </footer>
      </div>
    </>
  );
};

export default mainPage;
