import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.scss';
import reportWebVitals from './reportWebVitals';
import Main from './Components/mainPage';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

     <Main />
  



  </React.StrictMode>
);

reportWebVitals();
